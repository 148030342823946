import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { FormattedMessage } from "react-intl";

function HomeContact() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <section>
      <VisibilitySensor onChange={onChange} partialVisibility minTopValue={300}>
        <div className={`homeContact ${isVisible ? "visible" : ""}`}>
          <div className="contactTitle">
            <h1 className={`${isVisible ? "visible" : ""}`}>
              <FormattedMessage
                id="contactTitle"
                defaultMessage="Entre em contato conosco agora mesmo!"
              />
            </h1>
          </div>
          <div className="contactContent">
            <div className="text">
              <p className={`${isVisible ? "visible" : ""}`}>
                <FormattedMessage
                  id="contactDescription"
                  defaultMessage="Estamos disponíveis 24 horas por dia, 7 dias por semana, para garantir que suas necessidades sejam atendidas, pois nossas operações nunca param. Não hesite em nos chamar!"
                />
              </p>
              <a href="#">
                <span>
                  <FormattedMessage
                    id="contactButton"
                    defaultMessage="Entrar em contato"
                  />
                </span>
                <div className="liquid"></div>
              </a>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    </section>
  );
}

export default HomeContact;
