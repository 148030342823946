import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { FormattedMessage } from "react-intl";
import video from "../../../assets/img/vessels/vesselsOne.mp4";

function VesselsOne() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={100}>
      <div className={`vesselsOne ${isVisible ? "visible" : ""}`}>
        <video
          className={` ${isVisible ? "visible" : ""}`}
          autoPlay
          muted
          loop
          playsInline
          controlsList="disabled"
          src={video}
        ></video>
        <div className="vesselsText">
          <h1 className={` ${isVisible ? "visible" : ""}`}>
            <FormattedMessage
              id="vesselsOneTitle"
              defaultMessage="Embarcações prontas para enfrentar qualquer desafio."
            />
          </h1>
          <p className={` ${isVisible ? "visible" : ""}`}>
            <FormattedMessage
              id="vesselsOneText1"
              defaultMessage="Na Palangana, sabemos que a segurança e a eficiência são essenciais para qualquer operação marítima. Por isso, nossas embarcações são mantidas com o máximo cuidado e equipadas com tecnologia de ponta para garantir a melhor performance em cada viagem."
            />
          </p>
          <p className={` ${isVisible ? "visible" : ""}`}>
            <FormattedMessage
              id="vesselsOneText2"
              defaultMessage="Equipamentos modernos e um atendimento de excelência são nossos compromissos para oferecer segurança e conforto em cada operação."
            />
          </p>
          <p className={` ${isVisible ? "visible" : ""}`}>
            <FormattedMessage
              id="vesselsOneText3"
              defaultMessage="Confie na Palangana para um transporte marítimo de alta qualidade, com embarcações preparadas para atender às suas necessidades com a máxima segurança e eficiência."
            />
          </p>
        </div>
      </div>
    </VisibilitySensor>
  );
}

export default VesselsOne;
