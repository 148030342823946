import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { FormattedMessage } from "react-intl";

import img from "../../../assets/img/about/img-1.webp";
import border from "../../../assets/icon/border.webp";

function ServicesTwo() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
      <div className="servicesTwo" id="services">
        <div className="servicesTwoTitle">
          <h2 className={`${isVisible ? "visible" : ""}`}>
            <FormattedMessage
              id="servicesTwotitle"
              defaultMessage="Transporte"
            />
          </h2>
          <img src={border} alt="" />
        </div>
        <div className="service">
          <div className="servicesTwoText">
            <h1 className={`${isVisible ? "visible" : ""}`}>
              <FormattedMessage
                id="servicesTwoheader"
                defaultMessage="Transporte de Passageiros e Cargas"
              />
            </h1>
            <p className={`${isVisible ? "visible" : ""}`}>
              <FormattedMessage
                id="servicesTwodescription"
                defaultMessage="Dispomos de uma frota própria de embarcações projetadas para o transporte seguro e eficiente de passageiros e cargas. Nossas operações abrangem tanto a baía de Paranaguá e Antonina, atendendo a uma variedade de demandas logísticas. Cada embarcação é equipada com tecnologia de ponta e recursos que garantem conforto e segurança aos nossos passageiros, além de permitir o transporte de cargas com diferentes especificações. Além disso, oferecemos suporte completo às tripulações dos navios, incluindo serviços de abastecimento, manutenção e logística, garantindo que todas as operações sejam realizadas de maneira fluida e eficiente. Nossa equipe altamente qualificada está sempre à disposição para atender às necessidades específicas de cada cliente, proporcionando um serviço personalizado que reflete nosso compromisso com a excelência e a satisfação total. Com a Palangana, você pode contar com um parceiro confiável para todas as suas necessidades de transporte marítimo."
              />
            </p>
          </div>
          <div>
            <img src={img} alt="" />
          </div>
        </div>
      </div>
    </VisibilitySensor>
  );
}

export default ServicesTwo;
