import React, {useState} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import VisibilitySensor from "react-visibility-sensor";
import { useBlogContext } from "../contexts/BlogContext";
import arrowLeft from "../../../assets/img/components/arrow-left.webp"
import arrowRight from "../../../assets/img/components/arrow-right.webp";
import LoadingSpinner from "../../../components/LoadingSpinner";
import CardPublicationSmall from "./CardPublicationSmall";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";

function SwiperCard({ expandedCards = false }) {

  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  const breakpoints = {
    1600: {
      slidesPerView: 4,
    },
    991: {
      spaceBetween: 20,
      slidesPerView: 3,
    },
    600: {
      slidesPerView: 2,
    },
    0: {
      slidesPerView: 1,
    },
  };

  const { allPosts, isFetchingAllPosts } = useBlogContext()

 
  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={300}>
      <div id="card-publication-sm">
        <div className="carousel-wrapper">
          {isFetchingAllPosts ? (<div style={{ paddingTop: "15rem" }}><LoadingSpinner /></div>) : (

            allPosts?.data?.length === 0
              ? (<p style={{ paddingTop: "15rem" }}>Não foram encontrados posts.</p>)
              : (
                <Swiper
                  loop={true}
                  slidesPerView={3}
                  spaceBetween={10}
                  breakpoints={breakpoints}
                  pagination={{
                    dynamicBullets: true,
                  }}
                  navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                  }}
                  modules={[Pagination, Navigation]}
                  className={`${isVisible ? 'visible' : ''}`}
                >
                  <div className="swiper-button-prev"><img src={arrowLeft} width={'35px'} alt="" /></div>
                  {allPosts?.data?.map((post, index) => (
                  <div>
                    <SwiperSlide key={index}>
                      <CardPublicationSmall post={post} />
                    </SwiperSlide>
                  </div>
                  ))}
                  <div className="swiper-button-next"><img src={arrowRight} width={'35px'} alt="" /></div>
                </Swiper>
              )

          )}
        </div>
        <div className="gradient-white"></div>
      </div>
      </VisibilitySensor>
  );
}

export default React.memo(SwiperCard);
