export function generateClientErrorMessage(error, status = 400) {
    try {

        if (typeof error === 'string') {
            return `${status} - ${error}`
        }

        if (error.response.data.message) {
            return `${error.response.status} - ${error.response.data.message}`
        }
                
    } catch (clientError) {
        
        if(error.message.includes("Network Error")){
            return `500 - Erro ao realizar operação. Sem conexão com o servidor.`
        }

        return `500 - Não foi possível realizar a operação. Tente novamente mais tarde.`
    }
}