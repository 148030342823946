import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { FormattedMessage } from "react-intl";

function HomeTides() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <section>
      <VisibilitySensor onChange={onChange} partialVisibility minTopValue={300}>
        <div className={`homeTides ${isVisible ? "visible" : ""}`}>
          <div className="homeTidesTitle">
            <h1 className={`${isVisible ? "visible" : ""}`}>
              <FormattedMessage
                id="tidesTitle"
                defaultMessage="Tábua de Marés de Paranaguá"
              />
            </h1>
          </div>
          {/* <div className="waves2"></div> */}
          <div className="homeTidesContent">
            <p className={`${isVisible ? "visible" : ""}`}>
              <FormattedMessage
                id="tidesDescription"
                defaultMessage="Conheça as tábuas das marés e tabelas solunares de Paranaguá: hora, altura e coeficiente da preia-mar, baixa-mar; saída e pôr do sol, fases lunares e etc..."
              />
            </p>
            <a href="https://tabuademares.com/br/parana/paranagua#_mares" target="_blank">
              <span>
                <FormattedMessage id="follow" defaultMessage="Acompanhar" />
              </span>
              <div className="liquid"></div>
            </a>
          </div>
        </div>
      </VisibilitySensor>
    </section>
  );
}

export default HomeTides;
