import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { FormattedMessage } from "react-intl";

import img1 from "../../../assets/img/about/about.webp";

function AboutOne() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility>
      <div className={`aboutOne ${isVisible ? "visible" : ""}`}>
        <VisibilitySensor partialVisibility={false}>
          <div className="aboutOneC1">
            <img
              className={`${isVisible ? "visible" : ""}`}
              src={img1}
              alt=""
              width={"500px"}
            />
            <div className="text">
              <h1 className={`${isVisible ? "visible" : ""}`}>
                <FormattedMessage
                  id="aboutOneTitle"
                  defaultMessage="30 anos de qualidade e excelência"
                />
              </h1>
              <p className={`${isVisible ? "visible" : ""}`}>
                <FormattedMessage
                  id="aboutOneText"
                  defaultMessage="Nossa trajetória começou em 1995, em Paranaguá, Paraná, onde estabelecemos nossa matriz e estaleiro próprio. Desde o início, nos destacamos pela agilidade e eficiência no atendimento estratégico à frota de embarcações. Hoje, atuamos em três principais segmentos: transporte marítimo, apoio marítimo e apoio portuário, oferecendo uma ampla gama de serviços, como transporte de cargas e passageiros, reboque marítimo, fornecimento de água potável, salvatagem, amarração de navios e operação de postos marítimos de combustíveis. Com uma equipe altamente capacitada e preparada para qualquer operação, garantimos excelência e prontidão em cada serviço prestado."
                />
              </p>
            </div>
          </div>
        </VisibilitySensor>
      </div>
    </VisibilitySensor>
  );
}

export default AboutOne;
