import React, { useState } from "react";
import {
  CButton,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
} from "@coreui/react-pro";
import VisibilitySensor from "react-visibility-sensor";
import { FormattedMessage } from "react-intl";

import phone from "../../../assets/icon/telefone.svg";
import whats from "../../../assets/icon/whatsapp.svg";
import email from "../../../assets/icon/o-email.svg";
import { sendContactEmail } from "../../../services/sendContactEmail";
import { useAlert } from "../../../contexts/AlertContext";

function ContactOne() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  const defaultFormData = {
    name: "",
    enterprise: "",
    email: "",
    phone: "",
    message: "",
    setor: "",
    isLoadingResponse: false,
  };

  const [formData, setFormData] = useState(defaultFormData);

  const formatPhoneValue = (event) => {
    const formattedPhone = event.target.value
      .replace(/\D/g, "")
      .replace(/^(\d{2})(\d)/g, "($1) $2")
      .replace(/(\d)(\d{4})$/, "$1-$2");

    setFormData((prev) => ({ ...prev, phone: formattedPhone }));
  };

  const { showAlert } = useAlert();

  const handleSubmit = async () => {
    setFormData((prev) => ({ ...prev, isLoadingResponse: true }));

    const response = await sendContactEmail(formData);

    if (response.success) {
      setFormData(defaultFormData);
    }

    showAlert(response.message, response.success ? "success" : "error");
    setFormData((prev) => ({ ...prev, isLoadingResponse: false }));
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={100}>
      <div className={`contactOne ${isVisible ? "visible" : ""}`}>
        <CForm className={`${isVisible ? "visible" : ""}`}>
          <h1 className={`${isVisible ? "visible" : ""}`}>
            <FormattedMessage
              id="contactFormtitle"
              defaultMessage="Envie-nos uma mensagem agora mesmo!"
            />
          </h1>
          <div>
            <CFormLabel className="me-2 labelName">
              <FormattedMessage id="contactFormname" defaultMessage="Nome:" />
            </CFormLabel>
            <CFormInput
              type="text"
              maxLength={80}
              minLength={3}
              value={formData.name}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, name: e.target.value }))
              }
              className="me-2 inputName"
              placeholder=""
            ></CFormInput>

            <CFormLabel>
              <FormattedMessage
                id="contactFormemail"
                defaultMessage="E-mail:"
              />
            </CFormLabel>
            <CFormInput
              type="email"
              autoComplete="off"
              maxLength={60}
              className="me-2"
              placeholder=""
              value={formData.email}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, email: e.target.value }))
              }
            ></CFormInput>
            <CFormLabel className="mt-4">
              <FormattedMessage
                id="contactFormphone"
                defaultMessage="Telefone:"
              />
            </CFormLabel>
            <CFormInput
              type="phone"
              autoComplete="off"
              maxLength={15}
              minLength={15}
              onChange={formatPhoneValue}
              className="me-2"
              placeholder=""
              value={formData.phone}
            ></CFormInput>
            <CFormLabel className="mt-4">
              <FormattedMessage
                id="contactFormmessage"
                defaultMessage="Mensagem:"
              />
            </CFormLabel>
            <CFormTextarea
              type="text"
              autoComplete="off"
              maxLength={300}
              minLength={25}
              rows={4}
              as="text-area"
              className="me-2 message"
              placeholder=""
              value={formData.message}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, message: e.target.value }))
              }
            ></CFormTextarea>
            <CButton
              className="mt-4"
              onClick={handleSubmit}
              disabled={formData.isLoadingResponse}
            >
              <span>
                {formData.isLoadingResponse ? (
                  <FormattedMessage
                    id="contactFormsending"
                    defaultMessage="Enviando..."
                  />
                ) : (
                  <FormattedMessage
                    id="contactFormsend"
                    defaultMessage="Enviar"
                  />
                )}
              </span>
              <div className="liquid"></div>
            </CButton>
          </div>
        </CForm>
        <div className="contactOneText">
          <h3 className={`${isVisible ? "visible" : ""}`}>
            <FormattedMessage
              id="contactFormoperatingHours"
              defaultMessage="Operamos 24 horas por dia, 7 dias por semana. Não hesite em nos contatar a qualquer momento."
            />
          </h3>
          <p className={`address ${isVisible ? "visible" : ""}`}>
            R. Mal. Floriano, 19 - Oceania, Paranaguá - PR, 83203-390
          </p>
          <div className="contacts">
            <div className={`email ${isVisible ? "visible" : ""}`}>
              <img src={email} alt="" width={"25px"} height={"25px"} />
              <p>palangana@palangana.com.br</p>
            </div>
            <div className={`phone ${isVisible ? "visible" : ""}`}>
              <img src={phone} alt="" width={"15px"} />
              <p>(41) 3423-2544 </p>
            </div>
            <a href="https://api.whatsapp.com/send/?phone=5541999781014&text=Ol%C3%A1%2C%0A%0AEstou+entrando+em+contato+pelo+site+da+Palangana.+Poderia+me+ajudar%2C+por+favor%3F&type=phone_number&app_absent=0">
              <div className={`phone phone2 ${isVisible ? "visible" : ""}`}>
                <img src={whats} alt="" width={"20px"} />
                <p> (41) 99978-1014</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </VisibilitySensor>
  );
}
export default ContactOne;
