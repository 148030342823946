import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { FormattedMessage } from "react-intl";

function ServicesOne() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
      <div className="servicesOne" id="services">
        <div className="servicesOneText">
          <h1 className={`${isVisible ? "visible" : ""}`}>
            <FormattedMessage
              id="servicesOnetitle"
              defaultMessage="Navegue com a liderança em soluções marítimas"
            />
          </h1>
          <p className={`${isVisible ? "visible" : ""}`}>
            <FormattedMessage
              id="servicesOnedescription"
              defaultMessage="Desde 1995, a Palangana se destaca como líder em três segmentos essenciais nos portos de Paranaguá e Antonina. Com uma trajetória de sucesso, consolidamos nossa posição como referência no setor marítimo, oferecendo soluções de excelência e confiança."
            />
          </p>
        </div>
      </div>
    </VisibilitySensor>
  );
}

export default ServicesOne;
