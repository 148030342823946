import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { FormattedMessage } from "react-intl";

function HomeMap() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <section>
      <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
        <div className="homeMap">
          <div className="homeMap2">
            <h1 className={`${isVisible ? "visible" : ""}`}>
              <FormattedMessage
                id="mapTitle"
                defaultMessage="Endereço da Nossa Sede em Paranaguá"
              />
            </h1>
            <p className={`${isVisible ? "visible" : ""}`}>
              <FormattedMessage
                id="mapDescription"
                defaultMessage="Estamos localizados em Paranaguá na Rua Marechal Floriano, 19 - Oceania, Paranaguá - PR, 83203-390."
              />
            </p>

            <a href="/contact" reloadDocument className="button-green">
              <FormattedMessage id="mapButton" defaultMessage="Ver no mapa" />
            </a>
          </div>
          <div className="homeMap1">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3600.684374384115!2d-48.50238508443845!3d-25.51557084252794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94db9a466422f805%3A0xb8077c17ff4cee5a!2sPalangana%20Transportes%20Mar%C3%ADtimos%20e%20Apoio%20Portu%C3%A1rio!5e0!3m2!1spt-BR!2sbr!4v1633821709092!5m2!1spt-BR!2sbr"
              width="600"
              height="450"
              style={{ border: "5px" }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </VisibilitySensor>
    </section>
  );
}

export default HomeMap;
