import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import missao from "../../../assets/icon/missao.svg";
import visao from "../../../assets/icon/visao.svg";
import valores from "../../../assets/icon/valores.svg";
import { FormattedMessage } from "react-intl";

function AboutTwo() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <div className="aboutTwo">
      <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
        <div>
          <div className="aboutTwoTitle">
            <h1 className={`${isVisible ? "visible" : ""}`}>
              <FormattedMessage
                id="aboutTwoTitle"
                defaultMessage="Descubra a cultura organizacional que nos fortalece e impulsiona rumo à excelência."
              />
            </h1>
          </div>
          <div className="aboutTwoContent">
            <div className={`card1 card ${isVisible ? "visible" : ""}`}>
              <div className="cardText">
                <img src={missao} alt="" width={50} className="mb-2" />
                <h2>
                  <FormattedMessage id="missionTitle" defaultMessage="Missão" />
                </h2>
                <p>
                  <FormattedMessage
                    id="missionText"
                    defaultMessage="
                  Prestar serviços com excelência no transporte marítimo e no
                  apoio portuário, prezando pela qualidade, ótimo desempenho e
                  custo competitivo."
                  />
                </p>
              </div>
            </div>
            <div className={`card2 card ${isVisible ? "visible" : ""}`}>
              <div className="cardText">
                <img src={visao} alt="" width={60} className="mb-2" />
                <h2>
                  <FormattedMessage id="visionTitle" defaultMessage="Visão" />
                </h2>
                <p>
                  <FormattedMessage
                    id="visionText"
                    defaultMessage="
                  Ser e manter-se na liderança do segmento através da busca
                  constante pela qualidade."
                  />
                </p>
              </div>
            </div>
            <div className={`card3 card ${isVisible ? "visible" : ""}`}>
              <div className="cardText">
                <img src={valores} alt="" width={50} className="mb-2" />
                <h2>
                  <FormattedMessage id="valuesTitle" defaultMessage="Valores" />
                </h2>
                <p>
                  <FormattedMessage
                    id="valuesText"
                    defaultMessage="
                  Qualidade de serviço, sustentabilidade dos negócios, meio
                  ambiente, crescimento, segurança dos serviços e satisfação dos
                  clientes."
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    </div>
  );
}

export default AboutTwo;
