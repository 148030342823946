import React, { useState, useEffect } from "react";
import {
  CNavbar,
  CNavbarBrand,
  CContainer,
  CNavbarToggler,
  COffcanvas,
  COffcanvasHeader,
  COffcanvasTitle,
  CCloseButton,
  COffcanvasBody,
  CNavbarNav,
  CNavItem,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react-pro";
import { FormattedMessage } from "react-intl";
import { useLanguage } from "../utils/LanguageContext";

import logo from "../assets/icon/logo_verde2.png";
import { Link } from "react-router-dom";

function Navbar() {
  const [visible, setVisible] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { locale, setLocale } = useLanguage();

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLanguageChange = (lang) => {
    setLocale(lang); // Isso deve agora funcionar corretamente
  };
  return (
    <CNavbar
      className={`navbarWhite ${scrolled ? "scrolled" : ""}`}
      expand="xl"
      translate="no"
    >
      <div className="nav-container">
        <CContainer fluid>
          <CNavbarBrand className={`navLogo2`}>
            <a href="/">
              <img src={logo} alt="logo" width={180} />
            </a>
          </CNavbarBrand>
          <CNavbarToggler
            aria-controls="offcanvasNavbar2"
            aria-label="Toggle navigation"
            onClick={() => setVisible(!visible)}
          />
          <COffcanvas
            id="offcanvasNavbar2"
            placement="end"
            portal={false}
            visible={visible}
            onHide={() => setVisible(false)}
          >
            <COffcanvasHeader>
              <COffcanvasTitle></COffcanvasTitle>
              <CCloseButton
                className="text-reset"
                onClick={() => setVisible(false)}
              />
            </COffcanvasHeader>
            <COffcanvasBody>
              <div className="flex-content-nav">
                <CNavbarNav>
                  <CNavItem className={`px-1 homeLink`}>
                    <a href="/home" active reloadDocument>
                      <FormattedMessage id="home" defaultMessage="Home" />
                    </a>
                  </CNavItem>
                  <CNavItem className={`px-1 aboutLink`}>
                    <a href="/about" active reloadDocument>
                      <FormattedMessage id="about" defaultMessage="About" />
                    </a>
                  </CNavItem>
                  <CNavItem className={`px-1 vesselsLink`}>
                    <a href="/vessels" active reloadDocument>
                      <FormattedMessage id="career" defaultMessage="Career" />
                    </a>
                  </CNavItem>
                </CNavbarNav>
                <CNavbarBrand className={`navLogo`}>
                  <img src={logo} alt="logo" width={200} />
                </CNavbarBrand>
                <CNavbarNav>
                  <CNavItem className={`px-1 contactLink`}>
                    <a href="/services" active reloadDocument>
                      <FormattedMessage
                        id="Service"
                        defaultMessage="Services"
                      />
                    </a>
                  </CNavItem>
                  <CNavItem className={`px-1 contactLink`}>
                    <a href="/contact" active reloadDocument>
                      <FormattedMessage id="contact" defaultMessage="Contact" />
                    </a>
                  </CNavItem>
                  <CNavItem className={`px-1 blogLink`}>
                    <a href="/blog" active reloadDocument>
                      <FormattedMessage id="blog" defaultMessage="Blog" />
                    </a>
                  </CNavItem>
                  <CDropdown variant="btn-group" className="portalLink">
                    <CDropdownToggle>
                      <FormattedMessage
                        id="translation"
                        defaultMessage="Translation"
                      />
                    </CDropdownToggle>
                    <CDropdownMenu>
                      <CDropdownItem onClick={() => handleLanguageChange("pt")}>
                        <FormattedMessage
                          id="portuguese"
                          defaultMessage="Portuguese"
                        />
                      </CDropdownItem>
                      <CDropdownItem onClick={() => handleLanguageChange("en")}>
                        <FormattedMessage
                          id="english"
                          defaultMessage="English"
                        />
                      </CDropdownItem>
                      <CDropdownItem onClick={() => handleLanguageChange("es")}>
                        <FormattedMessage
                          id="spanish"
                          defaultMessage="Spanish"
                        />
                      </CDropdownItem>
                    </CDropdownMenu>
                  </CDropdown>
                </CNavbarNav>
              </div>
            </COffcanvasBody>
          </COffcanvas>
        </CContainer>
      </div>
    </CNavbar>
  );
}

export default Navbar;
