import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import VisibilitySensor from "react-visibility-sensor";

import amarracao from "../../../assets/img/about/img-3.webp";
import border from "../../../assets/icon/border.webp";

function ServicesFour() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={100}>
      <div className="servicesFour">
        <div className="servicesFourTitle">
          <h2 className={`${isVisible ? "visible" : ""}`}>
            <FormattedMessage
              id="servicesFourtitle"
              defaultMessage="Apoio Portuário"
            />
          </h2>
          <img src={border} alt="" />
        </div>
        <div className="service">
          <img src={amarracao} alt="" />
          <div className="servicesFourText">
            <h1 className={`${isVisible ? "visible" : ""}`}>
              <FormattedMessage
                id="servicesFourmooring"
                defaultMessage="Amarrações de Navios"
              />
            </h1>
            <p className={`${isVisible ? "visible" : ""}`}>
              <FormattedMessage
                id="servicesFourmooringDescription"
                defaultMessage="Oferecemos serviços de amarração de navios nos portos de Paranaguá e Antonina, onde somos homologados pela APPA (Administração dos Portos de Paranaguá e Antonina). Nossa equipe de profissionais altamente treinados garante que cada operação de amarração seja realizada com precisão e segurança, atendendo a todas as normas e regulamentações vigentes. Com um profundo conhecimento das condições locais e das características de cada embarcação, asseguramos um processo ágil e eficiente, minimizando riscos e maximizando a segurança das operações portuárias. Nossa expertise e compromisso com a excelência fazem de nós um parceiro confiável para todas as suas necessidades de amarração."
              />
            </p>
          </div>
        </div>
      </div>
    </VisibilitySensor>
  );
}

export default ServicesFour;
