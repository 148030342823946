import React, { useState, useRef } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { FormattedMessage } from "react-intl";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css";

import { Navigation } from "swiper/modules";
import arrowRight from "../../../assets/img/components/arrow-right2.webp";
import arrowLeft from "../../../assets/img/components/arrow-left2.webp";

import img1 from "../../../assets/img/vessels/vesselsTwo/embarcacao_palangana_i.webp";
import img2 from "../../../assets/img/vessels/vesselsTwo/embarcacao_palangana_ii.webp";
import img3 from "../../../assets/img/vessels/vesselsTwo/embarcacao_palangana_iii.webp";
import img4 from "../../../assets/img/vessels/vesselsTwo/embarcacao_palangana_iv.webp";
import img5 from "../../../assets/img/vessels/vesselsTwo/embarcacao_palangana_v.webp";
import img6 from "../../../assets/img/vessels/vesselsTwo/embarcacao_palangana_vi.webp";
import img7 from "../../../assets/img/vessels/vesselsTwo/embarcacao_palangana_vii.webp";
import img9 from "../../../assets/img/vessels/vesselsTwo/embarcacao_palangana_ix.webp";
import img10 from "../../../assets/img/vessels/vesselsTwo/embarcacao_palangana_x.webp";
import img11 from "../../../assets/img/vessels/vesselsTwo/embarcacao_palangana_kaskao.webp";
import img12 from "../../../assets/img/vessels/vesselsTwo/embarcacao_capitao_celso_magalhaes.webp";

const data = [
  {
    id: 1,
    image: img1,
    name: "Palangana I",
    text: (
      <FormattedMessage
        id="vesselsTwopalanganaI"
        defaultMessage="Embarcação para atendimento no ancoradouro interno, impulsionada por um motor Mercedes-Benz 336 com 300 cavalos. Com capacidade para até 12 passageiros, esta embarcação oferece uma jornada de conforto e desempenho."
      />
    ),
  },
  {
    id: 2,
    image: img2,
    name: "Palangana II",
    text: (
      <FormattedMessage
        id="vesselsTwopalanganaII"
        defaultMessage="Embarcação para atendimento no ancoradouro externo. Com 10 metros de comprimento e 3,60 metros de boca, esta lancha oferece espaço e comodidade para até 5 passageiros. Equipada com dois motores Mercedes-Benz 366 de 300 cavalos cada."
      />
    ),
  },
  {
    id: 3,
    image: img3,
    name: "Palangana III",
    text: (
      <FormattedMessage
        id="vesselsTwopalanganaIII"
        defaultMessage="A Palangana III é um bote de ferro robusto, projetado para auxiliar na amarração de navios, garantindo segurança e eficiência nas operações marítimas."
      />
    ),
  },
  {
    id: 4,
    image: img4,
    name: "Palangana IV",
    text: (
      <FormattedMessage
        id="vesselsTwopalanganaIV"
        defaultMessage="Embarcação para atendimento no ancoradouro interno. Equipada com um motor Mercedes-Benz 366 de 300 cavalos. Com dimensões generosas de 2,70 metros de boca por 10,30 metros de comprimento, e capacidade para até 12 passageiros, é a escolha ideal para uma jornada memorável."
      />
    ),
  },
  {
    id: 5,
    image: img5,
    name: "Palangana V",
    text: (
      <FormattedMessage
        id="vesselsTwopalanganaV"
        defaultMessage="Embarcação para atendimento no ancoradouro interno, equipada com um motor Mercedes-Benz 366 de 300 cavalos. Com capacidade para até 12 passageiros e dimensões generosas, é sinônimo de conforto e desempenho."
      />
    ),
  },
  {
    id: 6,
    image: img6,
    name: "Palangana VI",
    text: (
      <FormattedMessage
        id="vesselsTwopalanganaVI"
        defaultMessage="Embarcação para atendimento no ancoradouro interno, uma embarcação que supera expectativas. Equipada com um motor Mercedes-Benz 366 de 300 cavalos. Com capacidade para até 12 passageiros e dimensões generosas de 2,70 metros de boca por 10,30 metros de comprimento, é a escolha definitiva para quem busca o melhor em navegação."
      />
    ),
  },
  {
    id: 7,
    image: img7,
    name: "Palangana VII",
    text: (
      <FormattedMessage
        id="vesselsTwopalanganaVII"
        defaultMessage="Embarcação para atendimento no ancoradouro externo, equipada com dois robustos motores Mercedes-Benz 366 de 300 cavalos cada um. Esta embarcação oferece potência e desempenho incomparáveis. Com capacidade para até 5 passageiros, é a escolha perfeita para aventuras marítimas inesquecíveis."
      />
    ),
  },
  {
    id: 8,
    image: img9,
    name: "Palangana IX",
    text: (
      <FormattedMessage
        id="vesselsTwopalanganaIX"
        defaultMessage="Embarcação para atendimento no ancoradouro interno. Equipada com um motor Mercedes-Benz 366 de 300 cavalos. Com capacidade para até 12 passageiros, garante uma experiência de navegação inigualável."
      />
    ),
  },
  {
    id: 9,
    image: img10,
    name: "Palangana X",
    text: (
      <FormattedMessage
        id="vesselsTwopalanganaX"
        defaultMessage="Embarcação para atendimento no ancoradouro externo, uma lancha que impressiona em todos os aspectos. Com 10 metros de comprimento, 3,60 metros de boca e capacidade para até 5 passageiros, oferece conforto e espaço. Equipada com dois potentes motores Mercedes-Benz 366 de 300 cavalos cada um, é a escolha ideal para quem busca desempenho e elegância."
      />
    ),
  },
  {
    id: 10,
    image: img11,
    name: "Kaskão",
    text: (
      <FormattedMessage
        id="vesselsTwopalanganaKaskão"
        defaultMessage="O Kaskão é um bote de ferro confiável, projetado para auxiliar nas operações de amarração de navios, garantindo segurança e eficiência em todas as tarefas marítimas."
      />
    ),
  },
  {
    id: 11,
    image: img12,
    name: "Capitão Celso Magalhações",
    text: (
      <FormattedMessage
        id="vesselsTwopalanganaCapitãoCelso"
        defaultMessage="Embarcação bem equipada e confortável, ideal para o transporte de passageiros."
      />
    ),
  },
];

function VesselsTwo() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  const videoRefs = useRef(data.map(() => React.createRef()));

  const handleSlideChange = (swiper) => {
    const previousIndex = swiper.previousIndex;
    if (
      videoRefs.current[previousIndex] &&
      videoRefs.current[previousIndex].current
    ) {
      videoRefs.current[previousIndex].current.pause();
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={500}>
      <div className={`vesselsTwo ${isVisible ? "visible" : ""}`}>
        <div className="vesselsTwoTitle">
          <h1 className={`${isVisible ? "visible" : ""}`}>
            <FormattedMessage
              id="vesselsTwotitle"
              defaultMessage="Nossas embarcações"
            />
          </h1>
          <p className={`${isVisible ? "visible" : ""}`}>
            <FormattedMessage
              id="vesselsTwodescription"
              defaultMessage="Conheça a frota da Palangana e explore cada uma de nossas embarcações."
            />
          </p>
        </div>
        <Swiper
          className={`${isVisible ? "visible" : ""}`}
          effect={"fade"}
          modules={[Navigation]}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
          speed={700}
          onSlideChange={(swiper) => handleSlideChange(swiper)}
        >
          <div className="swiper-button-prev">
            <img src={arrowLeft} width={"35px"} alt="" />
          </div>
          {data.map((item, index) => (
            <SwiperSlide key={item.id}>
              <div className="card1">
                <div className="card-media">
                  <img className="" src={item.image} width={"200px"} alt="" />
                  <div className="media-text">
                    <h1>{item.name}</h1>
                    <p>{item.text}</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="swiper-button-next">
            <img src={arrowRight} width={"35px"} alt="" />
          </div>
        </Swiper>
      </div>
    </VisibilitySensor>
  );
}

export default VesselsTwo;
