import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import logo from "../assets/icon/logo_branca.png";

function Footer() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={100}>
      <div className={`footer-bg ${isVisible ? "visible" : ""}`}>
        <div className="footer">
          <div className={`address ${isVisible ? "visible" : ""}`}>
            <p>
              <FormattedMessage
                id="footerAddress"
                defaultMessage="R. Mal. Floriano, 19 - Oceania, Paranaguá - PR, 83203-390"
              />
            </p>
          </div>
          <div className={`rights ${isVisible ? "visible" : ""}`}>
            <p>
              <FormattedMessage
                id="footerRights"
                defaultMessage="© 2024 - Palangana | Todos os Direitos Reservados"
              />
            </p>
          </div>
          <div className={`singlec ${isVisible ? "visible" : ""}`}>
            <p>
              <FormattedMessage
                id="footerSinglec"
                defaultMessage="A product developed by {link}"
                values={{
                  link: (
                    <a
                      href="https://singlec.com.br/"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "#fff" }}
                    >
                      Singlec
                    </a>
                  ),
                }}
              />
            </p>
          </div>
        </div>
        <div className="logo">
          <img
            className={`${isVisible ? "visible" : ""}`}
            src={logo}
            alt="Logotipo da empresa"
            width={"250px"}
          />
          <div className={`line ${isVisible ? "visible" : ""}`}></div>
        </div>
        <div className="footer2">
          <ul>
            <h2>
              <FormattedMessage id="footerCompany" defaultMessage="Empresa" />
            </h2>
            <div className="underline"></div>
            <li>
              <Link to="/about">
                <FormattedMessage id="footerAbout" defaultMessage="Sobre" />
              </Link>
            </li>
            <li>
              <Link to="/services">
                <FormattedMessage
                  id="footerServices"
                  defaultMessage="Embarcações"
                />
              </Link>
            </li>
            <li>
              <Link to="/warehouseStructure">
                <FormattedMessage id="footerContact" defaultMessage="Contato" />
              </Link>
            </li>
            <li>
              <Link to="/fleetStructure">
                <FormattedMessage id="footerBlog" defaultMessage="Blog" />
              </Link>
            </li>
          </ul>
          <ul>
            <h2>
              <FormattedMessage
                id="footerContactHeader"
                defaultMessage="Contato"
              />
            </h2>
            <div className="underline"></div>
            <li>
              <a href="mailto:palangana@palangana.com.br">
                <FormattedMessage
                  id="footerEmail1"
                  defaultMessage="palangana@palangana.com.br"
                />
                <br />
                <FormattedMessage
                  id="footerPhone1"
                  defaultMessage="(41) 3423-2544"
                />
              </a>
            </li>
            <br />
            <li>
              <a href="mailto:comercial@palangana.com.br">
                <FormattedMessage
                  id="footerEmail2"
                  defaultMessage="comercial@palangana.com.br"
                />
                <br />
                <FormattedMessage
                  id="footerPhone2"
                  defaultMessage="(41) 99949-7427"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </VisibilitySensor>
  );
}

export default Footer;
