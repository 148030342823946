import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./view/home/home";
import About from "./view/about/about";
import Vessels from "./view/vessels/vessels";
import Services from "./view/services/services";
import Contact from "./view/contact/contact";
import Blog from "./view/blog/Blog";
import VisiblePubli from "./view/blog/VisiblePubli";
import AllBlogPosts from "./view/blog/AllBlogPosts";
import Post from "./view/blog/Post";

function RouteApp() {

  return (
    <BrowserRouter>  
      <Routes>
        <Route path="*" name="Home" element={<Home />} />
        <Route path="/about" name="About" element={<About />} />
        <Route path="/vessels" name="Vessels" element={<Vessels />} />
        <Route path="/services" name="Services" element={<Services />} />
        <Route path="/contact" name="Contact" element={<Contact />} />
        <Route path="/blog" name="Blog" element={<Blog />}>
        <Route path="visiblePubli" name="VisiblePubli" element={<VisiblePubli />}/>
        <Route path="post" name="post" element={<Post />}/>
        <Route name="AllBlogPosts" index={true} element={<AllBlogPosts />}/>
        </Route>        
      </Routes>
    </BrowserRouter>
  );
}

export default RouteApp;
