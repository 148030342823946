import ServicesOne from "./components/ServicesOne";
import ServicesTwo from "./components/ServicesTwo";
import ServicesThree from "./components/ServicesThree";
import ServicesFour from "./components/ServicesFour";

import NavbarWhite from "../../components/NavbarWhite";
import Footer from "../../components/Footer";
import MediaButtons from "../../components/MediaButtons";
import ButtonTop from "../../components/ButtonTop";
import CookiesAccept from "../../components/CookiesAccept";

function Services() {
  return (
    <div id="services">
      <MediaButtons></MediaButtons>
      <ButtonTop></ButtonTop>
      <NavbarWhite></NavbarWhite>
      <CookiesAccept></CookiesAccept>
      <ServicesOne></ServicesOne>
      <ServicesThree></ServicesThree>
      <ServicesFour></ServicesFour>
      <ServicesTwo></ServicesTwo>
      <Footer></Footer>
    </div>
  );
}

export default Services;
