import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import { FormattedMessage } from "react-intl";

import { Navigation } from "swiper/modules";

import img1 from "../../../assets/img/about/img-1.webp";
import img2 from "../../../assets/img/about/img-2.webp";
import img3 from "../../../assets/img/about/img-3.webp";
import img4 from "../../../assets/img/about/img-4.webp";
import img5 from "../../../assets/img/about/img-5.webp";
import img6 from "../../../assets/img/about/img-6.webp";
import img7 from "../../../assets/img/about/img-7.webp";
import img8 from "../../../assets/img/about/img-8.webp";
import img9 from "../../../assets/img/about/img-9.webp";
import img10 from "../../../assets/img/about/img-10.webp";
import img11 from "../../../assets/img/about/img-11.webp";
import img12 from "../../../assets/img/about/img-12.webp";

import arrowRight from "../../../assets/img/components/arrow-right.webp";
import arrowLeft from "../../../assets/img/components/arrow-left.webp";

function VesselsFour() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={400}>
      <div className={`vesselsFour ${isVisible ? "visible" : ""}`}>
        <div className="vesselsFourTitle">
          <h1 className={`${isVisible ? "visible" : ""}`}>
            <FormattedMessage
              id="vesselsFourTitle"
              defaultMessage="Explore mais sobre nossas embarcações em nossa galeria de fotos."
            />
          </h1>
          <h2 className={`${isVisible ? "visible" : ""}`}></h2>
        </div>
        <Swiper
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          slidesPerView={3}
          spaceBetween={0}
          loop
          modules={[Navigation]}
          className={`${isVisible ? "visible" : ""}`}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            500: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
          }}
        >
          <div className={`swiper-button-prev ${isVisible ? "visible" : ""}`}>
            <img src={arrowLeft} width={"35px"} alt="" />
          </div>
          <SwiperSlide>
            <div className="card3 card">
              <img src={img1} alt="" />
              <img src={img2} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card3 card">
              <img src={img3} alt="" />
              <img src={img4} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card2 card">
              <img src={img5} alt="" />
              <img src={img6} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card3 card">
              <img src={img7} alt="" />
              <img src={img8} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card3 card">
              <img src={img9} alt="" />
              <img src={img10} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card1 card">
              <img src={img11} alt="" />
              <img src={img12} alt="" />
            </div>
          </SwiperSlide>

          <div className={`swiper-button-next ${isVisible ? "visible" : ""}`}>
            <img src={arrowRight} width={"35px"} alt="" />
          </div>
        </Swiper>
      </div>
    </VisibilitySensor>
  );
}

export default VesselsFour;
