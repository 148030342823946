import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { FormattedMessage } from "react-intl";

import img1 from "../../../assets/img/about/about.webp";
import img2 from "../../../assets/img/about/about_1.webp";

function HomeAbout() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <section>
      <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
        <div className="homeAbout">
          <div className="homeAboutImg mt-5">
            <img src={img1} alt="" width={350} height={200} className="img1" />
            <img src={img2} alt="" width={400} height={200} className="img2" />
          </div>
          <div className="homeAboutText">
            <h1 className={`${isVisible ? "visible" : ""}`}>
              <span>
                <FormattedMessage
                  id="aboutTitle"
                  defaultMessage="30 anos de experiência no mercado"
                />
              </span>
            </h1>
            <p className={`${isVisible ? "visible" : ""}`}>
              <FormattedMessage
                id="aboutText"
                defaultMessage="Somos uma empresa tradicional de Paranaguá, no estado do Paraná, com 30 anos de experiência em transporte, apoio marítimo e portuário. Contamos com uma frota própria e uma equipe altamente qualificada, dedicada a oferecer um serviço excepcional e garantir a satisfação total dos nossos clientes."
              />
            </p>
          </div>
        </div>
      </VisibilitySensor>
    </section>
  );
}

export default HomeAbout;
