import axios from 'axios'

const config = {
  baseURL: process.env.NODE_ENV === 'production' ? 'https://blog.palangana.com.br/api/' : 'http://localhost:4008/api',
}

const axiosConfig = axios.create(config)

axiosConfig.defaults.headers['Content-Type'] = 'application/json'

axiosConfig.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    window.dispatchEvent(new Event('authError'))
  }
  return error;
});

export const api = axiosConfig