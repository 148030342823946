import React from "react";

import NavbarWhite from "../../components/NavbarWhite";
import MediaButtons from "../../components/MediaButtons";
import Footer from "../../components/Footer";
import ButtonTop from "../../components/ButtonTop";
import CookiesAccept from "../../components/CookiesAccept";

import AboutOne from "./components/AboutOne";
import AboutTwo from "./components/AboutTwo";
import AboutThree from "./components/AboutThree";

function About() {
  return (
    <div id="about">
      <NavbarWhite></NavbarWhite>
      <MediaButtons></MediaButtons>
      <ButtonTop></ButtonTop>
      <CookiesAccept></CookiesAccept>
      <AboutOne></AboutOne>
      <AboutTwo></AboutTwo>
      <AboutThree></AboutThree>
      <Footer></Footer>
    </div>
  );
}

export default About;
