import React from "react";

import NavbarWhite from "../../components/NavbarWhite";
import MediaButtons from "../../components/MediaButtons";
import ButtonTop from "../../components/ButtonTop";
import Footer from "../../components/Footer";
import CookiesAccept from "../../components/CookiesAccept";

import VesselsOne from "./components/VesselsOne";
import VesselsTwo from "./components/VesselsTwo";
import VesselsFour from "./components/VesselsFour";

function Vessels() {
  return (
    <div id="vessels">
      <NavbarWhite />
      <ButtonTop></ButtonTop>
      <MediaButtons />
      <CookiesAccept></CookiesAccept>
      <VesselsTwo />
      <VesselsOne />
      <VesselsFour />
      <Footer />
    </div>
  );
}

export default Vessels;
