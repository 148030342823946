const translationHome = {
  pt: {
    home: "Início",
    about: "Sobre",
    career: "Embarcações",
    Service: "Serviços",
    contact: "Contato",
    blog: "Blog",
    translation: "Tradução",
    portuguese: "Português",
    english: "Inglês",
    spanish: "Espanhol",
    cookiesMessage:
      "Este site utiliza cookies para garantir a melhor experiência possível. Ao continuar navegando, você concorda com o uso de cookies.",
    acceptButton: "Eu Concordo",
    qualityAndSafety: "Qualidade e segurança em cada operação.",
    experience:
      "30 anos de sólida experiência em transporte marítimo e apoio portuário especializado.",
    aboutTitle: "30 anos de experiência no mercado",
    aboutText:
      "Somos uma empresa de Paranaguá, Paraná, com 30 anos de expertise em transporte, apoio marítimo e portuário. Nossa frota própria e equipe altamente qualificada estão comprometidas em oferecer um serviço excepcional, garantindo a máxima satisfação dos nossos clientes em cada operação.",
    servicesTitle: "Nossos Serviços",
    servicesDescription:
      "Desde 1995, iniciamos nossas operações e, desde então, lideramos três segmentos chave nos portos de Paranaguá e Antonina. Essa trajetória faz da Palangana uma referência no mercado marítimo.",
    learnMore: "Saiba mais",
    transportTitle: "Transporte",
    transportDescription:
      "Realizamos o transporte marítimo de passageiros e cargas com eficiência e segurança.",
    maritimeSupportTitle: "Apoio Marítimo",
    maritimeSupportDescription:
      "Prestamos apoio marítimo com uma frota própria, garantindo confiabilidade e prontidão nos serviços.",
    portSupportTitle: "Apoio Portuário",
    portSupportDescription:
      "Oferecemos apoio portuário com uma equipe altamente especializada, assegurando eficiência e qualidade em nossas operações.",
    contactTitle: "Entre em contato conosco agora!",
    contactDescription:
      "Estamos disponíveis 24 horas por dia, 7 dias por semana, para garantir que suas necessidades sejam atendidas, pois nossas operações não param. Não hesite em nos ligar!",
    contactButton: "Fale conosco",
    mapTitle: "Endereço da nossa Sede em Paranaguá",
    mapDescription:
      "Estamos localizados em Paranaguá, na Rua Marechal Floriano, 19 - Oceania, Paranaguá - PR, 83203-390.",
    mapButton: "Ver no mapa",
    feedTitle:
      "Explore nossas redes sociais e fique atualizado com nossas postagens.",
    weatherTitle: "Confira o clima atual em Paranaguá, PR",
    minTemperature: "Mínima:",
    maxTemperature: "Máxima:",
    currentTemperature: "Atual:",
    humidity: "Umidade:",
    windSpeed: "Velocidade do vento:",
    daysOfWeek: [
      "Domingo",
      "Segunda-feira",
      "Terça-feira",
      "Quarta-feira",
      "Quinta-feira",
      "Sexta-feira",
      "Sábado",
    ],
    handlingTitle: "Acompanhe as últimas manobras da Palangana",
    handlingTooltip:
      "Informações do site SINPRAPAR: https://www.sinprapar.com.br/",
    date: "Data",
    time: "Hora",
    ship: "Navio",
    maneuver: "Manobra",
    type: "Tipo",
    loa: "LOA",
    beam: "Boca",
    draft: "Calado",
    tba: "TBA",
    dwt: "DWT",
    imo: "IMO",
    tugs: "Rebocadores",
    mooring: "Atracação",
    agency: "Agência",
    flag: "Bandeira",
    indicative: "Indicativo",
    anchorage: "Fundeio",
    situation: "Situação",
    loading: "Carregando...",
    tidesTitle: "Tábua de Marés de Paranaguá",
    tidesDescription:
      "Conheça as tábuas das marés e tabelas solunares de Paranaguá: hora, altura e coeficiente da preia-mar, baixa-mar; saída e pôr do sol, fases lunares, etc...",
    follow: "Acompanhar",
    footerAddress: "R. Mal. Floriano, 19 - Oceania, Paranaguá - PR, 83203-390",
    footerRights: "© 2024 - Palangana | Todos os Direitos Reservados",
    footerSinglec: "A product developed by {link}",
    footerCompany: "Empresa",
    footerAbout: "Sobre",
    footerServices: "Serviços",
    footerContact: "Contato",
    footerBlog: "Blog",
    footerContactHeader: "Contato",
    footerEmail1: "palangana@palangana.com.br",
    footerPhone1: "(41) 3423-2544",
    footerEmail2: "comercial@palangana.com.br",
    footerPhone2: "(41) 99949-7427",
    aboutOneTitle: "30 Anos de Qualidade e Excelência",
    aboutOneText:
      "Nossa jornada começou em 1995, em Paranaguá, Paraná, onde estabelecemos nossa sede e estaleiro próprio. Desde o início, destacamo-nos pela agilidade e eficiência nos serviços estratégicos da frota...",
    politicTitle: "Política Ambiental",
    politicText:
      "O cumprimento da legislação ambiental; prevenir formas de poluição e reduzir impactos ambientais significativos; estabelecer e rever periodicamente os objetivos e procedimentos ambientais e promover a implantação de programas de gerenciamento ambiental.",
  },
  en: {
    home: "Home",
    about: "About",
    career: "Vessels",
    Service: "Servicios",
    contact: "Contact",
    blog: "Blog",
    translation: "Translation",
    portuguese: "Portuguese",
    english: "English",
    spanish: "Spanish",
    cookiesMessage:
      "This site uses cookies to ensure the best possible experience. By continuing to browse, you agree to the use of cookies.",
    acceptButton: "I Agree",
    qualityAndSafety: "Quality and safety in every operation.",
    experience:
      "30 years of solid experience in maritime transport and specialized port support.",
    aboutTitle: "Almost 30 years of experience in the market",
    aboutText:
      "We are a company from Paranaguá, Paraná, with 30 years of expertise in transportation, maritime and port support. Our own fleet and highly qualified staff are committed to providing an exceptional service, guaranteeing our clients' maximum satisfaction in every operation.",
    servicesTitle: "Our Services",
    servicesDescription:
      "Since 1995, we have started our operations and since then, we have led three key segments in the ports of Paranaguá and Antonina. This trajectory makes Palangana a reference in the maritime market.",
    learnMore: "Learn more",
    transportTitle: "Transport",
    transportDescription:
      "We provide maritime transport of passengers and cargo efficiently and safely.",
    maritimeSupportTitle: "Maritime Support",
    maritimeSupportDescription:
      "We provide maritime support with a fleet of our own vessels, ensuring reliability and readiness in services.",
    portSupportTitle: "Port Support",
    portSupportDescription:
      "We provide port support with a highly specialized team, ensuring efficiency and quality in our operations.",
    contactTitle: "Get in touch with us now!",
    contactDescription:
      "We are available 24 hours a day, 7 days a week to ensure your needs are met, as our operations never stop. Don't hesitate to call us!",
    contactButton: "Contact us",
    mapTitle: "Address of Our Headquarters in Paranaguá",
    mapDescription:
      "We are located in Paranaguá at Rua Marechal Floriano, 19 - Oceania, Paranaguá - PR, 83203-390.",
    mapButton: "View on map",
    feedTitle: "Explore our social networks and stay updated with our posts.",
    weatherTitle: "Check the current weather in Paranaguá, PR",
    minTemperature: "Min:",
    maxTemperature: "Max:",
    currentTemperature: "Current:",
    humidity: "Humidity:",
    windSpeed: "Wind speed:",
    daysOfWeek: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    handlingTitle: "Track the latest maneuvers of Palangana",
    handlingTooltip:
      "Information from the SINPRAPAR website: https://www.sinprapar.com.br/",
    date: "Date",
    time: "Time",
    ship: "Ship",
    maneuver: "Maneuver",
    type: "Type",
    loa: "LOA",
    beam: "Beam",
    draft: "Draft",
    tba: "TBA",
    dwt: "DWT",
    imo: "IMO",
    tugs: "Tugs",
    mooring: "Mooring",
    agency: "Agency",
    flag: "Flag",
    indicative: "Indicative",
    anchorage: "Anchorage",
    situation: "Situation",
    loading: "Loading...",
    tidesTitle: "Tábua de Marés de Paranaguá",
    tidesDescription:
      "Conheça as tábuas das marés e tabelas solunares de Paranaguá: hora, altura e coeficiente da preia-mar, baixa-mar; saída e pôr do sol, fases lunares e etc...",
    follow: "Acompanhar",
    tidesTitle: "Paranaguá Tide Table",
    tidesDescription:
      "Explore the tide tables and lunar tables for Paranaguá: time, height, and coefficient of high tide, low tide; sunrise and sunset, lunar phases, etc...",
    follow: "Follow",
    footerAddress: "R. Mal. Floriano, 19 - Oceania, Paranaguá - PR, 83203-390",
    footerRights: "© 2024 - Palangana | All Rights Reserved",
    footerSinglec: "A product developed by {link}",
    footerCompany: "Company",
    footerAbout: "About",
    footerServices: "Vessels",
    footerContact: "Contact",
    footerBlog: "Blog",
    footerContactHeader: "Contact",
    footerEmail1: "palangana@palangana.com.br",
    footerPhone1: "(41) 3423-2544",
    footerEmail2: "comercial@palangana.com.br",
    footerPhone2: "(41) 99949-7427",
    aboutOneTitle: " 30 Years of Quality and Excellence",
    aboutOneText:
      "Our journey began in 1995, in Paranaguá, Paraná, where we established our headquarters and own shipyard. From the beginning, we stood out for our agility and efficiency in strategic fleet service. Today, we operate in three main segments: maritime transport, maritime support, and port support, offering a wide range of services such as cargo and passenger transport, maritime towing, potable water supply, salvage, ship mooring, and fuel station operations. With a highly trained team prepared for any operation, we ensure excellence and readiness in every service provided.",
    aboutTwoTitle:
      "Discover the organizational culture that strengthens and drives us toward excellence.",
    missionTitle: "Mission",
    missionText:
      "Provide excellence in maritime transport and port support services, prioritizing quality, high performance, and competitive costs.",
    visionTitle: "Vision",
    visionText:
      "To be and remain a leader in the industry through the constant pursuit of quality.",
    valuesTitle: "Values",
    valuesText:
      "Service quality, business sustainability, environment, growth, service safety, and customer satisfaction.",
    vesselsOneTitle: "Vessels ready to face any challenge.",
    vesselsOneText1:
      "At Palangana, we understand that safety and efficiency are essential for any maritime operation. That's why our vessels are maintained with the utmost care and equipped with cutting-edge technology to ensure the best performance on each journey.",
    vesselsOneText2:
      "Modern equipment and excellence in service are our commitments to provide security and comfort in every operation.",
    vesselsOneText3:
      "Trust Palangana for high-quality maritime transportation, with vessels prepared to meet your needs with maximum safety and efficiency.",
    vesselsTwotitle: "Our boats",
    vesselsTwodescription:
      "Get to know Palangana's fleet and explore each of our boats.",
    vesselsTwopalanganaI:
      "Vessel for service in the inner anchorage, powered by a Mercedes-Benz 336 engine with 300 horsepower. With capacity for up to 12 passengers, this vessel offers a journey of comfort and performance.",
    vesselsTwopalanganaII:
      "Boat for service at the outer anchorage. With a length of 10 meters and a beam of 3.60 meters, this boat offers space and comfort for up to 5 passengers. Equipped with two Mercedes-Benz 366 engines with 300 horsepower each.",
    vesselsTwopalanganaIII:
      "The Palangana III is a robust iron dinghy designed to help moor ships, ensuring safety and efficiency in maritime operations.",
    vesselsTwopalanganaIV:
      "Vessel for service in the inner anchorage. Equipped with a 300-horsepower Mercedes-Benz 366 engine. With generous dimensions of 2.70 meters beam by 10.30 meters length, and capacity for up to 12 passengers, it is the ideal choice for a memorable journey.",
    vesselsTwopalanganaV:
      "Vessel for service in the inner anchorage, equipped with a 300-horsepower Mercedes-Benz 366 engine. With capacity for up to 12 passengers and generous dimensions, it is synonymous with comfort and performance.",
    vesselsTwopalanganaVI:
      "Vessel for service in the inner anchorage, a vessel that exceeds expectations. Equipped with a 300-horsepower Mercedes-Benz 366 engine. With capacity for up to 12 passengers and generous dimensions of 2.70 meters beam and 10.30 meters length, it is the definitive choice for those looking for the best in sailing.",
    vesselsTwopalanganaVII:
      "Vessel for service in the outer anchorage, equipped with two robust Mercedes-Benz 366 engines of 300 horsepower each. This boat offers unrivaled power and performance. With capacity for up to 5 passengers, it is the perfect choice for unforgettable sea adventures.",
    vesselsTwopalanganaIX:
      "Vessel for service in the inner anchorage. Equipped with a 300-horsepower Mercedes-Benz 366 engine. With capacity for up to 12 passengers, it guarantees an unparalleled sailing experience.",
    vesselsTwopalanganaX:
      "This is a boat that impresses in every respect. With a length of 10 meters, a beam of 3.60 meters and capacity for up to 5 passengers, it offers comfort and space. Equipped with two powerful Mercedes-Benz 366 engines with 300 horsepower each, it is the ideal choice for those looking for performance and elegance",
    vesselsTwopalanganaKaskão:
      "The Kaskão is a reliable iron dinghy designed to assist in ship mooring operations, guaranteeing safety and efficiency in all maritime tasks.",
    vesselsTwopalanganaCapitãoCelso:
      "A well-equipped and comfortable boat, ideal for passenger transportation.",
    vesselsFourTitle: " Explore more of our vessels in our photo gallery.",
    servicesOnetitle: "Navigate with leading maritime solutions",
    servicesOnedescription:
      "Since 1995, Palangana has stood out as a leader in three essential segments in the ports of Paranaguá and Antonina. With a successful track record, we have consolidated our position as a benchmark in the maritime sector, offering solutions of excellence and reliability.",
    servicesTwotitle: "Transportation",
    servicesTwoheader: "Passenger and cargo transportation",
    servicesTwodescription:
      "We have our own fleet of vessels designed for the safe and efficient transportation of passengers and cargo. Our operations cover both the Paranaguá and Antonina bays, meeting a variety of logistical demands. Each vessel is equipped with state-of-the-art technology and features that guarantee comfort and safety for our passengers, as well as allowing the transportation of cargo with different specifications. In addition, we offer complete support to the ships' crews, including supply, maintenance and logistics services, ensuring that all operations are carried out smoothly and efficiently. Our highly qualified team is always on hand to meet the specific needs of each client, providing a personalized service that reflects our commitment to excellence and total satisfaction. With Palangana, you can count on a reliable partner for all your maritime transportation needs.",
    "servicesThree.title": "Maritime Support",
    servicesThreetug: "Maritime Tugging",
    servicesThreetugDescription:
      "Our boats are fully equipped and prepared to perform maritime towing operations safely and efficiently, regardless of weather conditions or the type of vessel. With an experienced and qualified team, we ensure agile and reliable towing operations, contributing to the smoothness and safety of port activities.",
    servicesThreesalvage: "Salvage",
    servicesThreesalvageDescription:
      "Our sailors are highly trained to respond to maritime emergencies of any nature. With boats equipped with cutting-edge technology for rapid response, we are always ready to act in critical situations, offering an effective salvage service that prioritizes the safety of crews and vessels.",
    servicesThreewaterSupply: "Potable Water Supply",
    servicesThreewaterSupplyDescription:
      "Our boats are equipped to supply potable water efficiently, ensuring adequate supply for vessels under any circumstances. Our operation is fast and reliable, catering to both small boats and large ships.",
    servicesThreefuelStation: "Maritime Fuel Station",
    servicesThreefuelStationDescription:
      "Our maritime fuel station serves both Palangana operations and the general public. We offer marine diesel and gasoline with a quick and high-quality service, providing essential support for port activities and ensuring the continuous supply of vessels with trusted products.",
    servicesFourtitle: "Port Support",
    servicesFourmooring: "Ship Mooring",
    servicesFourmooringDescription:
      "We offer ship mooring services at the ports of Paranaguá and Antonina, where we are accredited by APPA (Administration of the Ports of Paranaguá and Antonina). Our team of highly trained professionals ensures that each mooring operation is carried out with precision and safety, meeting all current standards and regulations. With in-depth knowledge of local conditions and the characteristics of each vessel, we ensure a fast and efficient process, minimizing risks and maximizing the safety of port operations. Our expertise and commitment to excellence make us a reliable partner for all your mooring needs.",
    contactFormtitle: "Send us a message now!",
    contactFormname: "Name",
    contactFormemail: "E-mail",
    contactFormphone: "Telephone",
    contactFormmessage: "Message",
    contactFormsending: "Sending...",
    contactFormsend: "Send",
    contactFormoperatingHours:
      "We operate 24 hours a day, 7 days a week. Don't hesitate to contact us at any time.",
    newsletterTitle: "Subscribe to PalanganaNews",
    newsletterEmailLabel: "E-mail",
    newsletterSending: "Sending...",
    newsletterSend: "Send",
    newsletterInvalidEmail: "Please enter a valid email",
    blogTitle: "Check out our latest publications!",
    politicTitle: "Environmental Policy",
    politicText:
      "Compliance with environmental legislation; preventing forms of pollution and reducing significant environmental impacts; establishing and periodically reviewing environmental objectives and procedures, and promoting the implementation of environmental management programs.",
  },
  es: {
    aboutOneTitle: " 30 Years of Quality and Excellence",
    home: "Inicio",
    about: "Acerca de",
    career: "Embarcaciones",
    Service: "Services",
    contact: "Contacto",
    blog: "Blog",
    translation: "Traducción",
    portuguese: "Portugués",
    english: "Inglés",
    spanish: "Español",
    cookiesMessage:
      "Este sitio usa cookies para garantizar la mejor experiencia posible. Al continuar navegando, aceptas el uso de cookies.",
    acceptButton: "Acepto",
    qualityAndSafety: "Calidad y seguridad en cada operación.",
    experience:
      "30 años de sólida experiencia en transporte marítimo y apoyo portuario especializado.",
    aboutTitle: "Casi 30 años de experiencia en el mercado",
    aboutText:
      "Somos una empresa de Paranaguá, Paraná, con 30 años de experiencia en transporte, apoyo marítimo y portuario. Contamos con flota propia y personal altamente calificado, comprometidos con la prestación de un servicio excepcional, garantizando la máxima satisfacción de nuestros clientes en cada operación.",
    servicesTitle: "Nuestros Servicios",
    servicesDescription:
      "Desde 1995, hemos comenzado nuestras operaciones y desde entonces, hemos liderado tres segmentos clave en los puertos de Paranaguá y Antonina. Esta trayectoria convierte a Palangana en una referencia en el mercado marítimo.",
    learnMore: "Saber más",
    transportTitle: "Transporte",
    transportDescription:
      "Realizamos el transporte marítimo de pasajeros y carga de manera eficiente y segura.",
    maritimeSupportTitle: "Apoyo Marítimo",
    maritimeSupportDescription:
      "Proporcionamos apoyo marítimo con una flota de embarcaciones propias, asegurando confiabilidad y prontitud en los servicios.",
    portSupportTitle: "Apoyo Portuario",
    portSupportDescription:
      "Brindamos apoyo portuario con un equipo altamente especializado, garantizando eficiencia y calidad en nuestras operaciones.",
    contactTitle: "¡Ponte en contacto con nosotros ahora mismo!",
    contactDescription:
      "Estamos disponibles las 24 horas del día, los 7 días de la semana, para garantizar que se satisfagan tus necesidades, ya que nuestras operaciones nunca se detienen. ¡No dudes en llamarnos!",
    contactButton: "Contactar",
    mapTitle: "Dirección de Nuestra Sede en Paranaguá",
    mapDescription:
      "Estamos ubicados en Paranaguá en Rua Marechal Floriano, 19 - Oceania, Paranaguá - PR, 83203-390.",
    mapButton: "Ver en el mapa",
    feedTitle:
      "Explore nuestras redes sociales y manténgase actualizado con nuestras publicaciones.",
    weatherTitle: "Verifique el clima actual en Paranaguá, PR",
    minTemperature: "Mínima:",
    maxTemperature: "Máxima:",
    currentTemperature: "Actual:",
    humidity: "Humedad:",
    windSpeed: "Vel. del viento:",
    daysOfWeek: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    handlingTitle: "Sigue las últimas maniobras de Palangana",
    handlingTooltip:
      "Información del sitio web de SINPRAPAR: https://www.sinprapar.com.br/",
    date: "Fecha",
    time: "Hora",
    ship: "Barco",
    maneuver: "Maniobra",
    type: "Tipo",
    loa: "LOA",
    beam: "Manga",
    draft: "Calado",
    tba: "TBA",
    dwt: "DWT",
    imo: "IMO",
    tugs: "Remolcadores",
    mooring: "Amarre",
    agency: "Agencia",
    flag: "Bandera",
    indicative: "Indicativo",
    anchorage: "Anclaje",
    situation: "Situación",
    loading: "Cargando...",
    tidesTitle: "Tabla de Mareas de Paranaguá",
    tidesDescription:
      "Conoce las tablas de mareas y las tablas de fases lunares de Paranaguá: hora, altura y coeficiente de la pleamar, bajamar; salida y puesta del sol, fases lunares, etc...",
    follow: "Seguir",
    footerAddress: "R. Mal. Floriano, 19 - Oceania, Paranaguá - PR, 83203-390",
    footerRights: "© 2024 - Palangana | Todos los Derechos Reservados",
    footerSinglec: "Un producto desarrollado por {link}",
    footerCompany: "Empresa",
    footerAbout: "Sobre",
    footerServices: "Embarcaciones",
    footerContact: "Contacto",
    footerBlog: "Blog",
    footerContactHeader: "Contacto",
    footerEmail1: "palangana@palangana.com.br",
    footerPhone1: "(41) 3423-2544",
    footerEmail2: "comercial@palangana.com.br",
    footerPhone2: "(41) 99949-7427",
    aboutOneTitle: "30 años de calidad y excelencia",
    aboutOneText:
      "Nuestro viaje comenzó en 1995, en Paranaguá, Paraná, donde establecimos nuestra sede y astillero propio. Desde el inicio, nos destacamos por nuestra agilidad y eficiencia en el servicio estratégico de la flota de embarcaciones. Hoy, operamos en tres segmentos principales: transporte marítimo, apoyo marítimo y apoyo portuario, ofreciendo una amplia gama de servicios como transporte de carga y pasajeros, remolque marítimo, suministro de agua potable, salvamento, amarre de barcos y operaciones de estaciones de combustible. Con un equipo altamente capacitado y preparado para cualquier operación, garantizamos excelencia y prontitud en cada servicio brindado.",
    aboutTwoTitle:
      "Descubre la cultura organizacional que nos fortalece y nos impulsa hacia la excelencia.",
    missionTitle: "Misión",
    missionText:
      "Brindar servicios de excelencia en transporte marítimo y apoyo portuario, priorizando la calidad, el alto rendimiento y los costos competitivos.",
    visionTitle: "Visión",
    visionText:
      "Ser y mantenerse en el liderazgo del sector a través de la búsqueda constante de la calidad.",
    valuesTitle: "Valores",
    valuesText:
      "Calidad de servicio, sostenibilidad empresarial, medio ambiente, crecimiento, seguridad del servicio y satisfacción del cliente.",
    vesselsOneTitle: "Embarcaciones listas para enfrentar cualquier desafío.",
    vesselsOneText1:
      "En Palangana, sabemos que la seguridad y la eficiencia son esenciales para cualquier operación marítima. Por eso, nuestras embarcaciones son mantenidas con el mayor cuidado y equipadas con tecnología de punta para garantizar el mejor rendimiento en cada viaje.",
    vesselsOneText2:
      "Equipos modernos y un servicio de excelencia son nuestros compromisos para ofrecer seguridad y comodidad en cada operación.",
    vesselsOneText3:
      "Confíe en Palangana para un transporte marítimo de alta calidad, con embarcaciones preparadas para atender sus necesidades con máxima seguridad y eficiencia.",
    vesselsTwotitle: "Nuestros barcos",
    vesselsTwodescription:
      "Conozca la flota de Palangana y explore cada uno de nuestros barcos.",
    vesselsTwopalanganaI:
      "Embarcación para servicio en el anclaje interno, impulsada por un motor Mercedes-Benz 336 con 300 caballos de fuerza. Con capacidad para hasta 12 pasajeros, esta embarcación ofrece un viaje de confort y rendimiento.",
    vesselsTwopalanganaII:
      "Embarcación para servicio en el anclaje externo. Con una longitud de 10 metros y una manga de 3,60 metros, esta lancha ofrece espacio y comodidad para hasta 5 pasajeros. Equipado con dos motores Mercedes-Benz 366 de 300 caballos de fuerza cada uno.",
    vesselsTwopalanganaIII:
      "La Palangana III es un bote de hierro robusto, diseñado para ayudar en el amarre de barcos, garantizando seguridad y eficiencia en las operaciones marítimas.",
    vesselsTwopalanganaIV:
      "Embarcación para servicio en el anclaje interno. Equipado con un motor Mercedes-Benz 366 de 300 caballos de fuerza. Con dimensiones generosas de 2,70 metros de manga por 10,30 metros de longitud, y capacidad para hasta 12 pasajeros, es la opción ideal para un viaje memorable.",
    vesselsTwopalanganaV:
      "Embarcación para servicio en el anclaje interno, equipada con un motor Mercedes-Benz 366 de 300 caballos de fuerza. Con capacidad para hasta 12 pasajeros y dimensiones generosas, es sinónimo de confort y rendimiento.",
    vesselsTwopalanganaVI:
      "Embarcación para servicio en el anclaje interno, una embarcación que supera las expectativas. Equipado con un motor Mercedes-Benz 366 de 300 caballos de fuerza. Con capacidad para hasta 12 pasajeros y dimensiones generosas de 2,70 metros de manga y 10,30 metros de longitud, es la elección definitiva para quienes buscan lo mejor en navegación.",
    vesselsTwopalanganaVII:
      "Embarcación para servicio en el anclaje externo, equipada con dos robustos motores Mercedes-Benz 366 de 300 caballos de fuerza cada uno. Esta embarcación ofrece potencia y rendimiento inigualables. Con capacidad para hasta 5 pasajeros, es la opción perfecta para aventuras marítimas inolvidables.",
    vesselsTwopalanganaIX:
      "Embarcación para servicio en el anclaje interno. Equipado con un motor Mercedes-Benz 366 de 300 caballos de fuerza. Con capacidad para hasta 12 pasajeros, garantiza una experiencia de navegación incomparable.",
    vesselsTwopalanganaX:
      "Esta es una lancha que impresiona en todos los aspectos. Con una longitud de 10 metros, una manga de 3,60 metros y capacidad para hasta 5 pasajeros, ofrece comodidad y espacio. Equipado con dos potentes motores Mercedes-Benz 366 de 300 caballos de fuerza cada uno, es la opción ideal para quienes buscan rendimiento y elegancia.",
    vesselsTwopalanganaKaskão:
      "El Kaskão es un bote de hierro confiable, diseñado para ayudar en las operaciones de amarre de barcos, garantizando seguridad y eficiencia en todas las tareas marítimas.",
    vesselsTwopalanganaCapitãoCelso:
      "Una embarcación bien equipada y cómoda, ideal para el transporte de pasajeros.",
    vesselsFourTitle: " Explore más embarcaciones en nuestra galería de fotos.",
    servicesOnetitle: "Navegue con las mejores soluciones marítimas",
    servicesOnedescription:
      "Desde 1995, Palangana se destaca como líder en tres segmentos esenciales en los puertos de Paranaguá y Antonina. Con una trayectoria de éxito, nos hemos consolidado como referencia en el sector marítimo, ofreciendo soluciones de excelencia y fiabilidad",
    servicesTwotitle: "Transporte",
    servicesTwoheader: "Transporte de pasajeros y mercancías",
    servicesTwodescription:
      "Contamos con una flota propia de embarcaciones diseñadas para el transporte seguro y eficiente de pasajeros y carga. Nuestras operaciones cubren las bahías de Paranaguá y Antonina, atendiendo a las más diversas demandas logísticas. Cada embarcación está equipada con tecnología de punta y características que garantizan comodidad y seguridad para nuestros pasajeros, además de permitir el transporte de cargas con diferentes especificaciones. Además, ofrecemos un apoyo completo a las tripulaciones de los buques, incluyendo servicios de abastecimiento, mantenimiento y logística, garantizando que todas las operaciones se lleven a cabo con fluidez y eficacia. Nuestro equipo altamente cualificado está siempre disponible para atender las necesidades específicas de cada cliente, proporcionando un servicio personalizado que refleja nuestro compromiso con la excelencia y la satisfacción total. Con Palangana, puede contar con un socio fiable para todas sus necesidades de transporte marítimo.",
    servicesThreetug: "Remolque Marítimo",
    servicesThreetugDescription:
      "Nuestros botes están completamente equipados y preparados para realizar operaciones de remolque marítimo de forma segura y eficiente, independientemente de las condiciones climáticas o del tipo de embarcación. Con un equipo experimentado y calificado, garantizamos operaciones de remolque ágiles y confiables, contribuyendo a la fluidez y seguridad de las actividades portuarias.",
    servicesThreesalvage: "Rescate",
    servicesThreesalvageDescription:
      "Nuestros marineros están altamente capacitados para responder a emergencias marítimas de cualquier índole. Con botes equipados con tecnología de punta para una respuesta rápida, estamos siempre listos para actuar en situaciones críticas, ofreciendo un servicio de rescate eficaz que prioriza la seguridad de las tripulaciones y embarcaciones.",
    servicesThreewaterSupply: "Suministro de Agua Potable",
    servicesThreewaterSupplyDescription:
      "Nuestros botes están equipados para suministrar agua potable de manera eficiente, garantizando un suministro adecuado para las embarcaciones en cualquier circunstancia. Nuestra operación es rápida y confiable, atendiendo tanto a embarcaciones pequeñas como a grandes barcos.",
    servicesThreefuelStation: "Estación Marítima de Combustible",
    servicesThreefuelStationDescription:
      "Nuestra estación marítima de combustible atiende tanto las operaciones de Palangana como al público en general. Ofrecemos diésel marino y gasolina con un servicio ágil y de alta calidad, proporcionando un apoyo fundamental para las actividades portuarias y garantizando el suministro continuo de las embarcaciones con productos de confianza.",
    servicesFourtitle: "Soporte Portuario",
    servicesFourmooring: "Amarre de Embarcaciones",
    servicesFourmooringDescription:
      "Ofrecemos servicios de amarre de embarcaciones en los puertos de Paranaguá y Antonina, donde estamos acreditados por la APPA (Administración de los Puertos de Paranaguá y Antonina). Nuestro equipo de profesionales altamente capacitados garantiza que cada operación de amarre se realice con precisión y seguridad, cumpliendo con todas las normas y regulaciones vigentes. Con un profundo conocimiento de las condiciones locales y las características de cada embarcación, aseguramos un proceso ágil y eficiente, minimizando riesgos y maximizando la seguridad de las operaciones portuarias. Nuestra experiencia y compromiso con la excelencia nos convierten en un socio confiable para todas sus necesidades de amarre.",
    contactFormtitle: "¡Envíanos un mensaje ahora!",
    contactFormname: "Nombre",
    contactFormemail: "Correo electrónico",
    contactFormphone: "Teléfono",
    contactFormmessage: "Mensaje",
    contactFormsending: "Enviando...",
    contactFormsend: "Enviar",
    contactFormoperatingHours:
      "Operamos 24 horas al día, 7 días a la semana. No dudes en contactarnos en cualquier momento.",
    newsletterTitle: "Suscríbete a PalanganaNews",
    newsletterEmailLabel: "Correo electrónico",
    newsletterSending: "Enviando...",
    newsletterSend: "Enviar",
    newsletterInvalidEmail: "Por favor, introduce un correo electrónico válido",
    blogTitle: "¡Consulte nuestras últimas publicaciones!",
    politicTitle: "Política Ambiental",
    politicText:
      "Cumplir con la legislación ambiental; prevenir formas de contaminación y reducir impactos ambientales significativos; establecer y revisar periódicamente los objetivos y procedimientos ambientales, y promover la implementación de programas de gestión ambiental.",
  },
};

export default translationHome;
