import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import mais from "../assets/img/mediaButtons/mais.webp";
import insta from "../assets/img/mediaButtons/logotipo-do-instagram.webp";
import whats from "../assets/img/mediaButtons/whatsapp.webp";
import linkedin from "../assets/img/mediaButtons/linkedin.webp";

function MediaButtons() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility>
      <div className={`mediaButtons ${isVisible ? "visible" : ""}`}>
        <div className="insta link">
          <a
            href="https://www.instagram.com/palangana_oficial/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={insta} alt="logotipo do instagram" width={"25px"} />
          </a>
        </div>
        <div className="whats link">
          <a
            href="https://wa.me/5541999781014?text=Ol%C3%A1%2C%0A%0AEstou%20entrando%20em%20contato%20pelo%20site%20da%20Palangana.%20Poderia%20me%20ajudar%2C%20por%20favor%3F"
            target="_blank"
            rel="noreferrer"
          >
            <img src={whats} alt="logotipo do whatsapp" width={"25px"} />
          </a>
        </div>
        <div className="linkedin link">
          <a
            href="https://www.linkedin.com/company/palangana-oficial/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedin} alt="logotipo do linkedin" width={"25px"} />
          </a>
        </div>
      </div>
    </VisibilitySensor>
  );
}
export default MediaButtons;
