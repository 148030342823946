import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import { FormattedMessage } from "react-intl";

function AboutThree() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <div className="aboutThree">
      <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
        <div className="aboutThreeTitle">
          <div>
            <h1 className={`${isVisible ? "visible" : ""}`}>
              <FormattedMessage
                id="politicTitle"
                defaultMessage="Política Ambiental"
              />
            </h1>
            <p>
              <FormattedMessage
                id="politicText"
                defaultMessage="
              O cumprimento da legislação ambiental; prevenir formas de poluição e reduzir impactos ambientais
              significativos; estabelecer e rever periodicamente os objetivos e procedimentos ambientais e promover a
              implantação de programas de gerenciamento ambiental."
              />
            </p>
          </div>
        </div>
      </VisibilitySensor>
    </div>
  );
}

export default AboutThree;
