import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { FormattedMessage } from "react-intl"; // Importa FormattedMessage
import Waves from "src/components/Waves";

import "swiper/css";
import "swiper/css/effect-fade";

import video from "../../../assets/img/home/hero/heroVideo.mp4";

function HomeHero() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <section>
      <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
        <div className="homeHeroBg">
          <Waves></Waves>
          <video
            autoPlay
            muted
            loop
            playsInline
            controlsList="disabled"
            src={video}
            frameborder="0"
          ></video>
          <div className="bg-green"></div>
          <div className="homeHero">
            <Swiper
              allowTouchMove={false}
              className="mySwiper swiperText"
              modules={[Autoplay]}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              speed={1200}
              loop
              breakpoints={{
                0: {
                  direction: "horizontal",
                },
                769: {
                  direction: "vertical",
                },
              }}
            >
              <SwiperSlide>
                <h1 className={`${isVisible ? "visible" : ""}`}>
                  <FormattedMessage
                    id="qualityAndSafety"
                    defaultMessage="30 anos de experiência sólida no transporte marítimo e suporte portuário especializado."
                  />
                </h1>
              </SwiperSlide>
              <SwiperSlide>
                <h1 className={`${isVisible ? "visible" : ""}`}>
                  <FormattedMessage
                    id="experience"
                    defaultMessage="Garantia de segurança em cada operação, do início ao fim."
                  />
                </h1>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </VisibilitySensor>
    </section>
  );
}

export default HomeHero;
