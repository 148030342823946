import React, { useState } from "react";
import axios from "axios";
import VisibilitySensor from "react-visibility-sensor";
import { FormattedMessage } from "react-intl";

import like from "../../../assets/img/home/feed/gostar.png";
import commit from "../../../assets/img/home/feed/bate-papo.png";
import send from "../../../assets/img/home/feed/instagram-direto.png";
import save from "../../../assets/img/home/feed/salvar-instagram.png";
import logo from "../../../assets/icon/logo_verde.png";

function HomeFeed() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  const clip = document.querySelectorAll(".clip");
  for (let i = 0; i < clip.length; i++) {
    clip[i].addEventListener("mouseenter", function (e) {
      clip[i].play();
    });
    clip[i].addEventListener("mouseout", function (e) {
      clip[i].pause();
    });
  }

  const [feedList, setFeedList] = React.useState([]);
  const [itemId0, setItemId0] = useState(null);
  const [itemId1, setItemId1] = useState(null);
  const [itemId2, setItemId2] = useState(null);

  async function getInstaFeed() {
    const token = process.env.REACT_APP_INSTA_TOKEN;
    const fields = "media_url,media_type,permalink,caption, timestamp";
    const url = `https://graph.instagram.com/me/media?access_token=${token}&fields=${fields}`;

    const { data } = await axios.get(url);
    const images = data.data.filter(
      (item) =>
        item.media_type === "IMAGE" || item.media_type === "CAROUSEL_ALBUM"
      /* item.media_type === "VIDEO" */
    );
    const limitedImages = images.slice(0, 3); // Define o número máximo de imagens a serem exibidas (neste exemplo, 8)
    setFeedList(limitedImages);
    setItemId0(limitedImages[0].id);
    setItemId1(limitedImages[1].id);
    setItemId2(limitedImages[2].id);
  }

  React.useEffect(() => {
    getInstaFeed();
  }, []);

  return (
    <section>
      <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
        <div className="homeFeed">
          <div className="homeFeedTitle">
            <h1 className={`${isVisible ? "visible" : ""}`}>
              <FormattedMessage
                id="feedTitle"
                defaultMessage="Explore nossas redes sociais e mantenha-se atualizado com nossas postagens."
              />
            </h1>
          </div>
          <div className="homeFeed2">
            <div className="card card1">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
              {feedList.map((item) => {
                if (item.id === itemId0) {
                  return (
                    <>
                      {item.media_type === "IMAGE" ||
                      item.media_type === "CAROUSEL_ALBUM" ? (
                        <div className="card-media">
                          <img src={item.media_url} rel="preload" alt="" />
                          <div className="media-text">
                            <p>{item.caption}</p>
                            <a
                              href={item.permalink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              ver mais...
                            </a>
                          </div>
                        </div>
                      ) : (
                        <div className="card-media">
                          <video
                            class="clip"
                            id="media-video"
                            type="video/mp4"
                            r
                            rel="preload"
                            loop
                            Muted
                          >
                            <source src={item.media_url} />
                          </video>
                          <div className="media-text text-video">
                            <p>{item.caption}</p>
                            <a
                              href={item.permalink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              ver mais...
                            </a>
                          </div>
                        </div>
                      )}
                    </>
                  );
                }
                return null;
              })}
              <div className="instaList">
                <div className="instaList1">
                  <img className="like" src={like} alt="" />
                  <img className="comment" src={commit} alt="" />
                  <img className="send" src={send} alt="" />
                </div>
                <img className="save" src={save} alt="" />
              </div>
            </div>
            <div className="card card2">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
              {feedList.map((item) => {
                if (item.id === itemId1) {
                  return (
                    <>
                      {item.media_type === "IMAGE" ||
                      item.media_type === "CAROUSEL_ALBUM" ? (
                        <div className="card-media">
                          <img src={item.media_url} rel="preload" alt="" />
                          <div className="media-text">
                            <p>{item.caption}</p>
                            <a
                              href={item.permalink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              ver mais...
                            </a>
                          </div>
                        </div>
                      ) : (
                        <div className="card-media">
                          <video
                            class="clip"
                            id="media-video"
                            type="video/mp4"
                            r
                            rel="preload"
                            loop
                            Muted
                          >
                            <source src={item.media_url} />
                          </video>
                          <div className="media-text text-video">
                            <p>{item.caption}</p>
                            <a
                              href={item.permalink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              ver mais...
                            </a>
                          </div>
                        </div>
                      )}
                    </>
                  );
                }
                return null;
              })}
              <div className="instaList">
                <div className="instaList1">
                  <img className="like" src={like} alt="" />
                  <img className="comment" src={commit} alt="" />
                  <img className="send" src={send} alt="" />
                </div>
                <img className="save" src={save} alt="" />
              </div>
            </div>
            <div className="card card3">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
              {feedList.map((item) => {
                if (item.id === itemId2) {
                  return (
                    <>
                      {item.media_type === "IMAGE" ||
                      item.media_type === "CAROUSEL_ALBUM" ? (
                        <div className="card-media">
                          <img src={item.media_url} rel="preload" alt="" />
                          <div className="media-text">
                            <p>{item.caption}</p>
                            <a
                              href={item.permalink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              ver mais...
                            </a>
                          </div>
                        </div>
                      ) : (
                        <div className="card-media">
                          <video
                            class="clip"
                            id="media-video"
                            type="video/mp4"
                            r
                            rel="preload"
                            loop
                            Muted
                          >
                            <source src={item.media_url} />
                          </video>
                          <div className="media-text text-video">
                            <p>{item.caption}</p>
                            <a
                              href={item.permalink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              ver mais...
                            </a>
                          </div>
                        </div>
                      )}
                    </>
                  );
                }
                return null;
              })}
              <div className="instaList">
                <div className="instaList1">
                  <img className="like" src={like} alt="" />
                  <img className="comment" src={commit} alt="" />
                  <img className="send" src={send} alt="" />
                </div>
                <img className="save" src={save} alt="" />
              </div>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    </section>
  );
}

export default HomeFeed;
