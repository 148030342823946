import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { FormattedMessage } from "react-intl";

import arrowRight from "../../../assets/img/components/arrow-right.webp";
import arrowLeft from "../../../assets/img/components/arrow-left.webp";
import salvatagem from "../../../assets/img/vessels/vesselsTwo/embarcacao_palangana_v.webp";
import reboque from "../../../assets/img/about/img-1.webp";
import aguapotavel from "../../../assets/img/vessels/vesselsTwo/embarcacao_palangana_i.webp";
import postocombustivel from "../../../assets/img/about/about.webp";
import border from "../../../assets/icon/border.webp";

function ServicesThree() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={100}>
      <div className="servicesThree">
        <div className="servicesThreeTitle">
          <h2 className={`${isVisible ? "visible" : ""}`}>
            <FormattedMessage
              id="servicesThreetug"
              defaultMessage="Apoio Marítimo"
            />
          </h2>
          <img src={border} alt="" />
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          pagination={{
            dynamicBullets: true,
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          modules={[Pagination, Navigation]}
          className={`${isVisible ? "visible" : ""}`}
        >
          <div className="swiper-button-prev">
            <img src={arrowLeft} width={"35px"} alt="" />
          </div>
          <SwiperSlide className="service">
            <div>
              <img src={reboque} alt="" />
            </div>
            <div className="servicesThreeText">
              <h1 className={`${isVisible ? "visible" : ""}`}>
                <FormattedMessage
                  id="servicesThreetug"
                  defaultMessage="Reboque Marítimo"
                />
              </h1>
              <p className={`${isVisible ? "visible" : ""}`}>
                <FormattedMessage
                  id="servicesThreetugDescription"
                  defaultMessage="Nossas lanchas são totalmente equipadas e preparadas para realizar reboques marítimos com segurança e eficiência, independentemente das condições climáticas ou do tipo de embarcação. Com uma equipe experiente e capacitada, garantimos operações de reboque ágeis e confiáveis, contribuindo para a fluidez e segurança das atividades portuárias."
                />
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="service">
            <div className="servicesThreeText">
              <h1 className={`${isVisible ? "visible" : ""}`}>
                <FormattedMessage
                  id="servicesThreesalvage"
                  defaultMessage="Salvatagem"
                />
              </h1>
              <p className={`${isVisible ? "visible" : ""}`}>
                <FormattedMessage
                  id="servicesThreesalvageDescription"
                  defaultMessage="Nossos marinheiros são altamente treinados para responder a emergências marítimas de qualquer natureza. Com lanchas equipadas com tecnologia de ponta para resposta rápida, estamos sempre prontos para agir em situações críticas, oferecendo um serviço de salvatagem eficaz que prioriza a segurança das tripulações e embarcações."
                />
              </p>
            </div>
            <div>
              <img src={salvatagem} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide className="service">
            <div>
              <img src={aguapotavel} alt="" />
            </div>
            <div className="servicesThreeText">
              <h1 className={`${isVisible ? "visible" : ""}`}>
                <FormattedMessage
                  id="servicesThreewaterSupply"
                  defaultMessage="Fornecimento de Água Potável"
                />
              </h1>
              <p className={`${isVisible ? "visible" : ""}`}>
                <FormattedMessage
                  id="servicesThreewaterSupplyDescription"
                  defaultMessage="Nossas lanchas estão preparadas para fornecer água potável de forma eficiente, garantindo o abastecimento adequado de embarcações em qualquer circunstância. Nossa operação é rápida e confiável, atendendo às necessidades tanto de pequenas embarcações quanto de grandes navios."
                />
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="service">
            <div className="servicesThreeText">
              <h1 className={`${isVisible ? "visible" : ""}`}>
                <FormattedMessage
                  id="servicesThreefuelStation"
                  defaultMessage="Posto Marítimo de Combustível"
                />
              </h1>
              <p className={`${isVisible ? "visible" : ""}`}>
                <FormattedMessage
                  id="servicesThreefuelStationDescription"
                  defaultMessage="Nosso posto marítimo de combustíveis atende tanto as operações da Palangana quanto o público geral. Oferecemos diesel marítimo e gasolina com um serviço ágil e de alta qualidade, proporcionando um suporte fundamental para as atividades portuárias e garantindo o abastecimento contínuo das embarcações com produtos de confiança."
                />
              </p>
            </div>
            <div>
              <img src={postocombustivel} alt="" />
            </div>
          </SwiperSlide>
          <div className="swiper-button-next">
            <img src={arrowRight} width={"35px"} alt="" />
          </div>
        </Swiper>
      </div>
    </VisibilitySensor>
  );
}

export default ServicesThree;
