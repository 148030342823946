export const formatIsoDateToUTC = (dateString) => {

    const options = {
        year: 'numeric',
        month: ('long' || 'short' || 'numeric'),
        weekday: ('long' || 'short'),
        day: 'numeric',
    }

    if (dateString) {
        return new Date(dateString).toLocaleString('pt-br', options)
    }

    //retorna o dia atual caso nao seja informada uma data valida
    //apenas pra não exibir a mensagem "Invalid Date"
    return new Date().toLocaleString('pt-br', options)
}