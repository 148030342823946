import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import VisibilitySensor from "react-visibility-sensor";
import { FormattedMessage } from "react-intl";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";

import transporte from "../../../assets/icon/transporte.svg";
import apoioPortuario from "../../../assets/icon/apoio_portuario.svg";
import apoioMaritimo from "../../../assets/icon/apoio_maritimo.svg";

function HomeServices() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
      <section>
        <div className={`homeServices ${isVisible ? "visible" : ""}`}>
          <div className="homeServicesText">
            <h1 className={`${isVisible ? "visible" : ""}`}>
              <FormattedMessage
                id="servicesTitle"
                defaultMessage="Nossos serviços"
              />
            </h1>
            <p className={`${isVisible ? "visible" : ""}`}>
              <FormattedMessage
                id="servicesDescription"
                defaultMessage="Desde 1995, nós iniciamos nossas operações e desde então lideramos três segmentos fundamentais nos portos de Paranaguá e Antonina. Essa trajetória faz da Palangana uma referência no mercado marítimo."
              />
            </p>
            <a href="/services" reloadDocument>
              <button className={`${isVisible ? "visible" : ""}`}>
                <FormattedMessage id="learnMore" defaultMessage="Saiba mais" />
              </button>
            </a>
          </div>
          <div className="homeServicesItem">
            <div>
              <div className="item one">
                <img src={transporte} alt="" width="80" className="mb-4" />
                <h2>
                  <FormattedMessage
                    id="transportTitle"
                    defaultMessage="Transporte"
                  />
                </h2>
                <p>
                  <FormattedMessage
                    id="transportDescription"
                    defaultMessage="Realizamos o transporte marítimo de passageiros e cargas com eficiência e segurança."
                  />
                </p>
              </div>
              <div className="item two">
                <img src={apoioMaritimo} alt="" width="60" className="mb-4" />
                <h2>
                  <FormattedMessage
                    id="maritimeSupportTitle"
                    defaultMessage="Apoio Marítimo"
                  />
                </h2>
                <p>
                  <FormattedMessage
                    id="maritimeSupportDescription"
                    defaultMessage="Fornecemos apoio marítimo com uma frota de embarcações própria, garantindo confiabilidade e prontidão nos serviços."
                  />
                </p>
              </div>
            </div>
            <div className="item three">
              <img src={apoioPortuario} alt="" width="80" className="mb-4" />
              <h2>
                <FormattedMessage
                  id="portSupportTitle"
                  defaultMessage="Apoio Portuário"
                />
              </h2>
              <p>
                <FormattedMessage
                  id="portSupportDescription"
                  defaultMessage="Prestamos apoio portuário com uma equipe altamente especializada, garantindo eficiência e qualidade em nossas operações."
                />
              </p>
            </div>
          </div>
        </div>
      </section>
    </VisibilitySensor>
  );
}

export default HomeServices;
