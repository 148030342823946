import React from "react";
import { FormattedMessage } from "react-intl";
import SwiperCard from "./components/SwiperCard";
import Newsletter from "./components/Newsletter";

const AllBlogPosts = () => {
  return (
    <>
      <div className="blogTitle">
        <h1>
          <FormattedMessage
            id="blogTitle"
            defaultMessage="Confira nossas últimas publicações!"
          />
        </h1>
      </div>
      <SwiperCard />
      <Newsletter />
    </>
  );
};

export default React.memo(AllBlogPosts);
