import SwiperCard from "./components/SwiperCard";
import SectionVisiblePubli from "./components/SectionVisiblePubli";

function VisiblePubli() {
  return (
    <>
      <SectionVisiblePubli/>
      <h1 className="tittle-visible-publi container">Posts recentes</h1>
      <SwiperCard/>
    </>
  );
}

export default VisiblePubli;
