import React, { useState, useEffect } from "react";
import VisibilitySensor from "react-visibility-sensor";
import {
  CTable,
  CTableHead,
  CTableRow,
  CTableDataCell,
  CTableBody,
  CTableHeaderCell,
  CTooltip,
  CButton,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import * as icon from "@coreui/icons";
import { useIntl, FormattedMessage } from "react-intl"; // Adicionando suporte à tradução

function HomeHandling() {
  const [isVisible, setIsVisible] = useState(false);
  const [handling, setHandling] = useState([]);
  const intl = useIntl(); // Hook para pegar a instância do Intl

  useEffect(() => {
    async function fetchHandling() {
      try {
        const response = await fetch("/api/handling");
        const data = await response.json();
        setHandling(data);
      } catch (error) {
        console.error(intl.formatMessage({ id: "errorFetchingData" }), error);
      }
    }

    fetchHandling();
  }, [intl]);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <section>
      <VisibilitySensor onChange={onChange} partialVisibility minTopValue={300}>
        <div className={`homeHandling ${isVisible ? "visible" : ""}`}>
          <div className="handlingTitle">
            <h1>
              <FormattedMessage
                id="handlingTitle"
                defaultMessage="Acompanhe as últimas manobras da Palangana"
              />
            </h1>
            <CTooltip
              content={intl.formatMessage({
                id: "handlingTooltip",
                defaultMessage:
                  "Informações retiradas do site da SINPRAPAR: https://www.sinprapar.com.br/",
              })}
              placement="left"
            >
              <CButton>
                <CIcon
                  icon={icon.cilInfo}
                  width={30}
                  style={{ "--ci-primary-color": "#0f6641", zIndex: 999999 }}
                />
              </CButton>
            </CTooltip>
          </div>
          {handling.length > 0 ? (
            <div className="handlingTable">
              <CTable>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell scope="col">
                      <FormattedMessage id="date" defaultMessage="Data" />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      <FormattedMessage id="time" defaultMessage="Hora" />
                    </CTableHeaderCell>
                    <CTableHeaderCell className="navio" scope="col">
                      <FormattedMessage id="ship" defaultMessage="Navio" />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      <FormattedMessage
                        id="maneuver"
                        defaultMessage="Manobra"
                      />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      <FormattedMessage id="type" defaultMessage="Tipo" />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      <FormattedMessage id="loa" defaultMessage="LOA" />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      <FormattedMessage id="beam" defaultMessage="Boca" />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      <FormattedMessage id="draft" defaultMessage="Calado" />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      <FormattedMessage id="tba" defaultMessage="TBA" />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      <FormattedMessage id="dwt" defaultMessage="DWT" />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      <FormattedMessage id="imo" defaultMessage="IMO" />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      <FormattedMessage
                        id="tugs"
                        defaultMessage="Rebocadores"
                      />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      <FormattedMessage
                        id="mooring"
                        defaultMessage="Amarração"
                      />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      <FormattedMessage id="agency" defaultMessage="Agência" />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      <FormattedMessage id="flag" defaultMessage="Bandeira" />
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      <FormattedMessage
                        id="indicative"
                        defaultMessage="Indicativo"
                      />
                    </CTableHeaderCell>
                    <CTableHeaderCell className="fundeio" scope="col">
                      <FormattedMessage
                        id="anchorage"
                        defaultMessage="Fundeio da Barra"
                      />
                    </CTableHeaderCell>
                    <CTableHeaderCell className="situacao" scope="col">
                      <FormattedMessage
                        id="situation"
                        defaultMessage="Situação"
                      />
                    </CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                {handling.map((tide, index) => (
                  <CTableBody key={index}>
                    <CTableRow className={tide.Situacao}>
                      <CTableDataCell>{tide.Data}</CTableDataCell>
                      <CTableDataCell>{tide.Hora}</CTableDataCell>
                      <CTableDataCell>{tide.Navio}</CTableDataCell>
                      <CTableDataCell>{tide.Manobra}</CTableDataCell>
                      <CTableDataCell>{tide.Tipo}</CTableDataCell>
                      <CTableDataCell>{tide.LOA}</CTableDataCell>
                      <CTableDataCell>{tide.Boca}</CTableDataCell>
                      <CTableDataCell>{tide.Calado}</CTableDataCell>
                      <CTableDataCell>{tide.TBA}</CTableDataCell>
                      <CTableDataCell>{tide.DWT}</CTableDataCell>
                      <CTableDataCell>{tide.IMO}</CTableDataCell>
                      <CTableDataCell>{tide.Rebocadores}</CTableDataCell>
                      <CTableDataCell>{tide.Amarracao}</CTableDataCell>
                      <CTableDataCell>{tide.Agencia}</CTableDataCell>
                      <CTableDataCell>{tide.Bandeira}</CTableDataCell>
                      <CTableDataCell>{tide.Indicativo}</CTableDataCell>
                      <CTableDataCell>{tide.FundeioNaBarra}</CTableDataCell>
                      <CTableDataCell>{tide.Situacao}</CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                ))}
              </CTable>
            </div>
          ) : (
            <p>
              <FormattedMessage id="loading" defaultMessage="Carregando..." />
            </p>
          )}
        </div>
      </VisibilitySensor>
    </section>
  );
}

export default HomeHandling;
