import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { IntlProvider } from "react-intl";
import translations from "./utils/translations";
import { LanguageProvider, useLanguage } from "../src/utils/LanguageContext";

import "@coreui/coreui/dist/css/coreui.min.css";
import "./assets/scss/styles.scss";

const Root = () => {
  const { locale } = useLanguage();

  const [section, setSection] = useState("home");

  const messages = translations[section][locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      <App setSection={setSection} />
    </IntlProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LanguageProvider>
      <Root />
    </LanguageProvider>
  </React.StrictMode>
);
